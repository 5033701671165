import React, {useState} from 'react'
import {ExpedienteDetalle} from '../ExpedienteDetalle/ExpedienteDetalle'

function Expediente(props) {
  const [isVisible, setIsVisible] = useState({})

  const handleExpedienteClick = (index) => () => {
    setIsVisible((state) => ({
      ...state, // <-- copy previous state
      [index]: !state[index], // <-- update value by index key
    }))
  }

  const displayItems = (props) => {
    if (props) {
      const expedientes = props.expedientes
      if (expedientes) {
        if (expedientes.length > 0) {
          return expedientes.map((expediente, index) => {
            return (
              <div
                className={`card card-expediente shadow mb-5 ms-3 ${
                  expediente.notificacionVencida ? 'card-vencida' : ''
                }`}
                key={index}
                onClick={handleExpedienteClick(index)}
              >
                <div className='card-body'>
                  <span>Expediente: </span>
                  <span className='no-expediente'>
                    <b>{expediente.expediente}</b>
                  </span>{' '}
                  | <span className='parte-actora'>{expediente.parteActora}</span>
                  <span className='float-end'>
                    <b>Última notificación:</b> {expediente.fechaNotificacion}
                  </span>
                </div>
                <div className={`expediente-detalle p-5 ${isVisible[index] ? '' : 'd-none'}`}>
                  <table className='table table-row-dashed table-row-gray-500 gy-7'>
                    <thead>
                      <tr>
                        <th scope='col'>
                          <b>Fecha notificación</b>
                        </th>
                        <th scope='col'>
                          <b>Parte actora</b>
                        </th>
                        <th scope='col'>
                          <b>Parte demandada</b>
                        </th>
                        <th scope='col'>
                          <b>Parte notificada</b>
                        </th>
                        <th scope='col'>
                          <b>Fecha actuación</b>
                        </th>
                        <th scope='col'>
                          <b>Síntesis</b>
                        </th>
                        <th scope='col'>
                          <b>Magistrado</b>
                        </th>
                        <th scope='col'>
                          <b>Secretario</b>
                        </th>
                        <th scope='col'>
                          <b>Sala</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ExpedienteDetalle expediente={expediente} />
                    </tbody>
                  </table>
                </div>
              </div>
            )
          })
        } else {
          return <h3>No hay expedientes</h3>
        }
      } else {
        return <h3>No hay expedientes</h3>
      }
    } else {
      return <h3>No hay expedientes</h3>
    }
  }

  return <>{displayItems(props)}</>
}

export {Expediente}
