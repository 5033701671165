import React, {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import axios from 'axios'



function TablaExpedientes(props) {
  const expedientes = props.expedientes
  const API_URL = process.env.REACT_APP_API_URL

  

  const displayItems = () => {
    if (expedientes) {
      if (expedientes.length > 0) {
        return expedientes.map((expediente, index) => {
          return (
            <tr key={index}>
              <td>{expediente.expediente}</td>
              <td>{expediente.parteActora}</td>
              <td>{expediente.fechaAlta}</td>
              <td>{expediente.fechaNotificacion}</td>
              <td>{expediente.status}</td>
              <td><button  onClick={() => props.deleteExpediente(expediente.idExpediente)} className='btn btn-secondary'><i className="fas fa-trash"></i></button></td>
              
            </tr>
          )
        })
      }
    }
  }

  return <>{displayItems()}</>
}

export {TablaExpedientes}



