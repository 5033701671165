import React, {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {TablaExpedientes} from '../../components/TablaExpedientes/TablaExpedientes'
import axios from 'axios'
import {KTSVG} from '../../../_metronic/helpers'
import ReactPaginate from 'react-paginate'
import {Modal} from 'react-bootstrap'
import './Expedientes.css'
import Swal from 'sweetalert2'


function Expedientes() {
  const API_URL = process.env.REACT_APP_API_URL
  const {currentUser, logout} = useAuth()

  const fecha = new Date()
  const [fechaLastUpdate, setFechaLastUpdate] = useState(new Date())

  const [loadingPage, setLoadingPage] = useState(false)

  const [expedientes, setExpedientes] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [loadingNewExp, setLoadingNewExp] = useState(false)
  

  //HACER EL FILTRO DE STATUS
  const [StatusValue, setStatusValue] = useState('')

 

  let searchedExpedientes = new Array()


  if (expedientes.length !== 0) {
    searchedExpedientes = expedientes.filter((expediente) => {

      if(searchValue !=''){
        return expediente.expediente.includes(searchValue) || expediente.parteActora.includes(searchValue) || expediente.parteActora.toLowerCase().includes(searchValue) || expediente.parteActora.toUpperCase().includes(searchValue);
      }else if(StatusValue == 'TODOS'){
        return expediente
      }else{

        return expediente.status.includes(StatusValue)

      }
      
    })
  }




  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(25)

  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
  const currentRecords = searchedExpedientes.slice(indexOfFirstRecord, indexOfLastRecord)

  const [showNewExp, setShowNewExp] = useState(false)
  const handleCloseNewExp = () => setShowNewExp(false)
  const handleShowNewExp = () => setShowNewExp(true)

  const [showBulkLoad, setShowBulkLoad] = useState(false)
  const handleCloseBulkLoad = () => setShowBulkLoad(false)
  const handleShowBulkLoad = () => setShowBulkLoad(true)

  const paginate = ({selected}) => {
    setCurrentPage(selected + 1)
  }

  function addZero(i) {
    if (i < 10) {
      i = '0' + i
    }
    return i
  }

  function formatoFecha(fecha, formato) {
    const meses = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ]

    const map = {
      dd: fecha.getDate(),
      mm: meses[fecha.getMonth()],
      yyyy: fecha.getFullYear(),
      HH: addZero(fecha.getHours()),
      MM: addZero(fecha.getMinutes()),
    }

    return formato.replace(/mm|dd|HH|MM|yyyy/gi, (matched) => map[matched])
  }

  const uploadNewExp = () => {
    setLoadingNewExp(true)
    let data = []
    const expName = (document.getElementById('new-exp-name').value).replace(/ /g, "")
    const parteActora = document.getElementById('parte-actora').value

    data = {
      idUser: currentUser.id,
      expName: expName,
      parteActora: parteActora,
    }

 

    axios.post(API_URL + '/newExp', data, {}).then(
      (res) => {
        console.log(res)
        misExpedientes()
        setLoadingNewExp(false)
        handleCloseNewExp()
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha registrado el nuevo expediente",
          showConfirmButton: false,
          timer: 1500
        });
      },
      (error) => {
        alert(error)
        setLoadingNewExp(false)
      }
    )
  }


  const [file, setFile] = useState()

  function handleChange(event) {
    setFile(event.target.files[0])
  }
  const formData = new FormData();
  formData.append('file', file);
  formData.append('idUser', currentUser.id);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const uploadBulkLoad = () => {
    axios.post(API_URL + '/bulkLoad', formData, config).then(
      (res) => {
        console.log(res)
        misExpedientes()
        setLoadingNewExp(false)
        handleCloseBulkLoad()
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se han registrado los expedientes",
          showConfirmButton: false,
          timer: 1500
        });
      },
      (error) => {
        alert(error)
        setLoadingNewExp(false)
      }
    )
  }

  var removeByAttr = function(arr, attr, value){
    var i = arr.length;
    while (i--) {
      if(arr[i] && arr[i].hasOwnProperty(attr) && (arguments.length > 2 && arr[i][attr] === value )) {
        arr.splice(i,1);
      }
    }
    return arr;
  }

  const deleteExpediente = (idExpediente) => {
    setLoadingPage(true)

    let data = []
    data = {
      idExpediente: idExpediente
    }
    
    axios.post(API_URL + '/deleteExp', data, {}).then(
      (res) => {
        console.log(res) 
        misExpedientes()
        setLoadingPage(false)
      },
      (error) => {
        alert(error)
      }
    )
  }

  


  const misExpedientes = () => {

    setLoadingPage(true)
    axios
      .get(API_URL + '/expedientes/' + currentUser.id)
      .then((response) => {
        console.log(response.data)
        setExpedientes(response.data)
        setLoadingPage(false)
      })
      .catch((error) => console.error(error))
  }



  

  useEffect(() => {
    misExpedientes()
    
  }, [])

  return (
    <>
      {/* Modal nuevo expediente */}
      <Modal show={showNewExp} onHide={handleCloseNewExp}>
        <Modal.Header closeButton>
          <Modal.Title>Dar de alta expediente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='form-group col-md-12'>
              <label className='alerta-modal'><strong>Agrega el número de expediente tal y como aparece en el boletín. Asegúrate no haya espacios ni caracteres diferentes, ya que la más mínima variación, afectará en las notificaciones del mismo.</strong></label>
              <label>Nuevo expediente:</label>
              <input type='text' className='form-control' id='new-exp-name' autoFocus/>
            </div>
          </div>
          <br></br>
          <div className='row'>
            <div className='form-group col-md-12'>
              <label>Parte actora (opcional):</label>
              <input type='text' className='form-control' id='parte-actora' />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={handleCloseNewExp}>
            Cerrar
          </button>
          <button type='button' className='btn btn-primary' onClick={() => uploadNewExp()}>
            {!loadingNewExp && <span className='indicator-label'>Guardar</span>}
            {loadingNewExp && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Por favor espere...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal carga masiva */}
      <Modal show={showBulkLoad} onHide={handleCloseBulkLoad}>
        <Modal.Header closeButton>
          <Modal.Title>Carga masiva de expedientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='form-group col-md-12'>
              <label className='alerta-modal'><strong>1. Para iniciar, descarga el siguiente formato:</strong></label>
              <a href="https://api.adinte.legal/formato/Adinte-Carga-Masiva.xlsx" target='_blank' className='btn btn-primary btn-download'>Descargar formato</a>
            </div>
          </div>
          <br></br>
          <div className='row'>
            <div className='form-group col-md-12'>
              <label className='alerta-modal'><strong>2. Una vez descargado, llena los campos y adjúntalo:</strong></label><br></br>
              <label>Subir formato:</label>
              <input type='file' className='form-control' id='file-bulk-load' name='file-bulk-load'  autoFocus accept=".xlsx, .xls" required onChange={handleChange}/>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={handleCloseBulkLoad}>
            Cerrar
          </button>
          <button type='button' className='btn btn-primary' onClick={() => uploadBulkLoad()}>
            {!loadingNewExp && <span className='indicator-label'>Cargar</span>}
            {loadingNewExp && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Por favor espere...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Header */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-lg-6'>
          <h2>Bienvenido</h2>
          <p>{formatoFecha(fecha, 'dd de mm yyyy - HH:MM hrs')}</p>
        </div>
       

        <div className='text-right col-lg-6'>
          
          <button
            type='button'
            className='btn btn-primary float-end w-60'
            onClick={handleShowNewExp}
          >
            Nuevo expediente
          </button>
          <button
            type='button'
            className='btn float-end w-60 mr-10 btn-bulk-load'
            onClick={handleShowBulkLoad}
          >
            Carga masiva de expedientes
          </button>
        </div>
      </div>

      <div className='row gy-5 g-xl-8 card card-custom shadow mt-4 pb-10'>
        <div className='row p-6'>
          <div className='col-md-6'>
            <h2>Historial de expedientes</h2>
            <p>En este apartado podrás consultar los expedientes que se han dado de alta.</p>
          </div>
          <div className='col-md-3'>
            <select className="form-select" 
              aria-label="Selecciona" 
              id="estatus" 
              name="estatus" 
              onChange={(event) => {
                setStatusValue(event.target.value)
              }
              }>
              <option selected disabled>FILTRAR POR</option>
              <option value="SURTIÓ EFECTOS">SURTIÓ EFECTOS</option>
              <option value="AÚN NO SURTE EFECTOS">AÚN NO SURTE EFECTOS</option>
              <option value="SIN NOTIFICACIONES">SIN NOTIFICACIONES</option>
              <option value="TODOS">TODOS</option>

            </select>
          </div>
          <div className='col-md-3 text-right'>
            <div className='form-group float-end pt-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 ms-0'
              />
              <input
                type='text'
                className='form-control form-control-flush ps-10 inicio-search-box mt-n10'
                name='search'
                placeholder='Buscar expediente...'
                value={searchValue}
                onChange={(event) => {
                  setSearchValue(event.target.value)
                  setCurrentPage(1)
                }}
              />
            </div>
          </div>
        </div>

        {/* Exp container */}
        <div className='row mb-10 gy-5'>
          <div className='col-md-4'>
            
          </div>
          {!loadingPage && (
            <>
              <div className='expedientes'>
                <table className='table table-rounded table-hover table-row-bordered border gy-7 gs-7'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200 expedientes-header'>
                      <th scope='col'>
                        <b>Expediente</b>
                      </th>
                      <th scope='col'>
                        <b>Parte Actora</b>
                      </th>
                      <th scope='col'>
                        <b>Fecha Alta</b>
                      </th>
                      <th scope='col'>
                        <b>Fecha Última <br></br>Notificación</b>
                      </th>
                      <th scope='col'>
                        <b>Estatus</b>
                      </th>
                      <th scope='col'>
                        <b>Acciones</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <TablaExpedientes expedientes={currentRecords} deleteExpediente={deleteExpediente} />
                  </tbody>
                 
                </table>
              </div>
            </>
          )}
          {loadingPage && (
            <>
              <div className='d-flex flex-center'>
                <h2>
                  <span className='spinner-border spinner-border align-middle ms-2'></span>
                  Cargando...
                </h2>
              </div>
            </>
          )}
        </div>
        <div className='row text-center'><p>Mostrando {currentRecords.length} de {searchedExpedientes.length} resultados</p></div>
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(searchedExpedientes.length / recordsPerPage)}
          breakLabel={'...'}
          previousLabel={'< Anterior'}
          nextLabel={'Siguiente >'}
          containerClassName={'pagination'}
          pageLinkClassName={'page-number'}
          previousLinkClassName={'page-number'}
          nextLinkClassName={'page-number'}
          activeLinkClassName={'active'}
          forcePage={currentPage - 1}
          
        />
      </div>
    </>
  )
}

export {Expedientes}
