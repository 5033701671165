/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import Swal from 'sweetalert2'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  company: '',
  employees: '',
  state: '',
  city: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  acceptPrivacy: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Mínimo 3 símbolos')
    .max(50, 'Máximo 50 símbolos')
    .required('Nombre es requerido'),
  email: Yup.string()
    .email('Formato de correo incorrecto')
    .min(3, 'Mínimo 3 símbolos')
    .max(50, 'Máximo 50 símbolos')
    .required('Correo electrónico es requerido'),
  lastname: Yup.string()
    .min(3, 'Mínimo 3 símbolos')
    .max(50, 'Máximo 50 símbolos')
    .required('Apellido es requerido'),
  phone: Yup.number().min(10, 'Mínimo 10 caracteres'),
  company: Yup.string()
    .min(1, 'Mínimo 1 símbolos')
    .max(50, 'Máximo 50 símbolos')
    .required('Compañia es requerido'),
  employees: Yup.string().min(1, 'Mínimo 1 número').required('Número de empleados es requerido'),
  state: Yup.string().required('Estado es requerido'),
  city: Yup.string()
    .min(3, 'Mínimo 3 símbolos')
    .max(50, 'Máximo 50 símbolos'),
  password: Yup.string()
    .min(3, 'Mínimo 3 símbolos')
    .max(50, 'Máximo 50 símbolos')
    .required('Contraseña es requerida'),
  changepassword: Yup.string()
    .required('Confirmación de contraseña es requerida')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Contraseña y confirmación de contraseña no coinciden'
      ),
    }),
  acceptTerms: Yup.bool().required('Debes aceptar los Términos y Condiciones'),
  acceptPrivacy: Yup.bool().required('Debes aceptar el Aviso de Privacidad'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await register(
          values.email,
          values.firstname,
          values.lastname,
          values.phone,
          values.company,
          values.employees,
          values.city,
          values.state,
          values.password,
          values.changepassword
        )
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)

        // Swal.fire({
        //   title: "Gracias por tu registro",
        //   text: "Hemos enviado un link de verificación al correo electrónico utilizado en tu registro, favor de validarlo en tu bandeja de entrada. \nSi no encuentras el correo, favor de verificar en tu bandeja de correo no deseado.",
        //   confirmButtonColor: "#FE735B",
        //   confirmButtonText: "Entendido"
        // }).then(function () {
        //   // Redirect the user
        //   window.location.href = "/auth";
        // });

      

      } catch (error) {
        console.error(error)
        if(error = 400){
          setStatus('Los detalles de registro son incorrectos.')
        }

        if(error = 401){
          setStatus('Este correo ya se encuentra registrado, favor de iniciar sesión o recupera tu contraseña')
        }
        saveAuth(undefined)
        
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Crear una Cuenta</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          ¿Ya tienes una cuenta?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Iniciar sesión
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
      {/* end::Action */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>Ó</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='class="form-label fw-bolder text-dark fs-6'>Nombre</label>
          <input
            placeholder='Nombre'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Apellido</label>
          <input
            placeholder='Apellido'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Correo electrónico</label>
        <input
          placeholder='Correo electrónico'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Teléfono</label>
        <input
          placeholder='Teléfono'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.phone && formik.errors.phone},
            
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-7'>
          <label className='class="form-label fw-bolder text-dark fs-6 mb-0'>Empresa</label>
          <input
            placeholder='Empresa'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('company')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.company && formik.errors.company,
              },
              {
                'is-valid': formik.touched.company && !formik.errors.company,
              }
            )}
          />
          {formik.touched.company && formik.errors.company && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.company}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-5'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Número de empleados</label>
          <select
            {...formik.getFieldProps('employees')}
            className={clsx(
              'form-select form-select-lg form-select-solid',
              {
                'is-invalid': formik.touched.employees && formik.errors.employees,
              },
              {
                'is-valid': formik.touched.employees && !formik.errors.employees,
              }
            )}
          >
            <option selected disabled value=''>
              Seleccione una opción
            </option>
            <option value='1-5'>1-5</option>
            <option value='5-20'>5-20</option>
            <option value='20+'>+20</option>
          </select>

          {formik.touched.employees && formik.errors.employees && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.employees}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Estado</label>
        <select
          {...formik.getFieldProps('state')}
          className={clsx(
            'form-select form-select-lg form-select-solid',
            {
              'is-invalid': formik.touched.state && formik.errors.state,
            },
            {
              'is-valid': formik.touched.state && !formik.errors.state,
            }
          )}
        >
          <option selected disabled value=''>
            Seleccione una estado
          </option>
          <option value='Aguascalientes'>Aguascalientes</option>
          <option value='Baja California'>Baja California</option>
          <option value='Baja California Sur'>Baja California Sur</option>
          <option value='Campeche'>Campeche</option>
          <option value='Chiapas'>Chiapas</option>
          <option value='Chihuahua'>Chihuahua</option>
          <option value='Ciudad de México'>Ciudad de México</option>
          <option value='Coahuila'>Coahuila</option>
          <option value='Colima'>Colima</option>
          <option value='Durango'>Durango</option>
          <option value='Estado de México'>Estado de México</option>
          <option value='Guanajuato'>Guanajuato</option>
          <option value='Guerrero'>Guerrero</option>
          <option value='Hidalgo'>Hidalgo</option>
          <option value='Jalisco'>Jalisco</option>
          <option value='Michoacán'>Michoacán</option>
          <option value='Morelos'>Morelos</option>
          <option value='Nayarit'>Nayarit</option>
          <option value='Nuevo León'>Nuevo León</option>
          <option value='Oaxaca'>Oaxaca</option>
          <option value='Puebla'>Puebla</option>
          <option value='Querétaro'>Querétaro</option>
          <option value='Quintana Roo'>Quintana Roo</option>
          <option value='San Luis Potosí'>San Luis Potosí</option>
          <option value='Sinaloa'>Sinaloa</option>
          <option value='Sonora'>Sonora</option>
          <option value='Tabasco'>Tabasco</option>
          <option value='Tamaulipas'>Tamaulipas</option>
          <option value='Tlaxcala'>Tlaxcala</option>
          <option value='Veracruz'>Veracruz</option>
          <option value='Yucatán'>Yucatán</option>
          <option value='Zacatecas'>Zacatecas</option>
        </select>
        {formik.touched.state && formik.errors.state && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.state}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Ciudad</label>
        <input
          placeholder='Ciudad'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('city')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.city && formik.errors.city},
            
          )}
        />
        {formik.touched.city && formik.errors.city && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.city}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Contraseña</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Contraseña'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Usa 8 o más caracteres con una mezcla de letras, números y símbolos.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmar contraseña</label>
        <input
          type='password'
          placeholder='Confirmación de contraseña'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-4'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            Acepto los{' '}
            <a href='https://adinte.legal/terminos-y-condiciones/' className='ms-1 link-primary'>
              Términos y condiciones
            </a>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptPrivacy')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            Acepto el{' '}
            <a href='https://adinte.legal/aviso-de-privacidad/' className='ms-1 link-primary'>
              Aviso de Privacidad
            </a>
            .
          </label>
          {formik.touched.acceptPrivacy && formik.errors.acceptPrivacy && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptPrivacy}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={
            formik.isSubmitting ||
            !formik.isValid ||
            !formik.values.acceptTerms ||
            !formik.values.acceptPrivacy
          }
        >
          {!loading && <span className='indicator-label'>Enviar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Por favor espere...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancelar
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
