import axios from 'axios'
import React, {useEffect, useState} from 'react'

function ExpedienteDetalle(props) {
  const expediente = props.expediente

  const [expedienteDetalle, setExpedienteDetalle] = useState('')

  const API_URL = process.env.REACT_APP_API_URL

  const getExpedienteDetalle = () => {
    let data = []

    data = {
      expediente: expediente.expediente,
    }

    axios
      .post(API_URL + '/expDetail/', data, {
        // receive two parameter endpoint url ,form data
      })
      .then(
        (res) => {
          // then print response status
          console.log(res.data)
          setExpedienteDetalle(res.data)
          // setCheckColumnPromo(columnIsActive)
        },
        (error) => {
          alert(error)
        }
      )
  }

  useEffect(() => {
    getExpedienteDetalle()
  }, [expediente])

  const displayItems = () => {
    if (expedienteDetalle) {
      if (expedienteDetalle.length > 0) {
        return expedienteDetalle.map((detalle, index) => {
          return (
            <tr key={index}>
              <td>{detalle.fechaNotificacion}</td>
              <td>{detalle.parteActora}</td>
              <td>{detalle.parteDemandada}</td>
              <td>{detalle.parteNotificada}</td>
              <td>{detalle.fechaActuacion}</td>
              <td>{detalle.sintesis}</td>
              <td>{detalle.magistrado}</td>
              <td>{detalle.secretario}</td>
              <td>{detalle.sala}</td>
            </tr>
          )
        })
      } else {
        return <h3>No hay expedientes</h3>
      }
    } else {
      return <h3>No hay expedientes</h3>
    }
  }

  return <>{displayItems()}</>
}

export {ExpedienteDetalle}
