import React , {useEffect, useState} from 'react'

import './MiPerfil.css'
import axios from 'axios'
import {useAuth} from '../../modules/auth'

function MiPerfil() {

  const API_URL = process.env.REACT_APP_API_URL

  const {currentUser, logout} = useAuth()

 

  const [FirstName, setFirstName] = useState()
  const [LastName, setLastName] = useState()
  const [Email, setEmail] = useState()
  const [Phone, setPhone] = useState()
  const [Company, setCompany] = useState()
  const [Employees, setEmployees] = useState()
  const [City, setCity] = useState()
  const [State, setState] = useState()
  const [RegDate, setRegDate] = useState()
  const [DiasRestantes, setDiasRestantes] = useState()


  const myProfile = () => {
    axios
      .get(API_URL + '/myProfile/' + currentUser.id)
      .then((response) => {
        console.log(response.data)

        var first_name = response.data[0].first_name;
        var last_name = response.data[0].last_name;
        var email =response.data[0].email;
        var phone = response.data[0].phone;
        var company = response.data[0].company;
        var employees = response.data[0].employees;
        var city = response.data[0].city;
        var state = response.data[0].state;
        var reg_date = response.data[0].reg_date;
        var dias_restantes = response.data[0].dias_restantes;

        console.log(first_name)

        setFirstName(first_name);
        setLastName(last_name);
        setEmail(email);
        setPhone(phone);
        setCompany(company);
        setEmployees(employees);
        setCity(city);
        setState(state);
        setRegDate(reg_date);
        setDiasRestantes(dias_restantes);
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    myProfile()
  }, [])


  return (
    <>
     <form className="card card-custom card-stretch">
  <div className="card-header py-3">
    <div className="card-title align-items-start flex-column">
      <h3 className="card-label font-weight-bolder text-dark">
        Mi perfil
      </h3>
      {/*<span className="text-muted font-weight-bold font-size-sm mt-1">
        Actualizar tu información personal
      </span>
      */}
    </div>
    {/*<div className="card-toolbar">
      <button type="submit" className="btn btn-success mr-2">
        Guardar
      </button>
      <a
        className="btn btn-secondary"
        href="/metronic/react/demo1/user-profile/profile-overview"
      >
        Cancel
      </a>
    </div>*/}
  </div>
  <div className="form">
    <div className="card-body">
      <div className="row">
        <label className="col-xl-3" />
        <div className="col-lg-9 col-xl-6">
          <h5 className="font-weight-bold mb-6">Información de usuario</h5>
        </div>
      </div>
      <div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label">Nombre</label>
        <div className="col-lg-9 col-xl-6">
          <input
            type="text"
            placeholder=""
            className="form-control form-control-lg form-control-solid"
            name="firstname"
            defaultValue={FirstName}
            readOnly
          />
        </div>
      </div>
      <div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label">Apellidos</label>
        <div className="col-lg-9 col-xl-6">
          <input
            type="text"
            placeholder=""
            className="form-control form-control-lg form-control-solid"
            name="lastname"
            defaultValue={LastName}
            readOnly
          />
        </div>
      </div>
      <div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label">Fecha de registro</label>
        <div className="col-lg-9 col-xl-6">
          <input
            type="text"
            placeholder=""
            className="form-control form-control-lg form-control-solid"
            name="companyName"
            defaultValue={RegDate}
            readOnly
          />
        </div>
      </div>
      {/*<div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label label-red">PLAN ACTUAL: 90 días de prueba.</label>
        <div className="col-lg-9 col-xl-6">
          <label className="col-xl-12 col-lg-12 col-form-label label-red">La pureba finaliza en {currentUser.expiration_date} días naturales.</label>
        </div>
  </div>*/}
      <div className="row">
        <label className="col-xl-3" />
        <div className="col-lg-9 col-xl-6">
          <h5 className="font-weight-bold mt-10 mb-6">Información de contacto</h5>
        </div>
      </div>
      <div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label">
          Teléfono
        </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group input-group-lg input-group-solid">

            <input
              type="text"
              placeholder=""
              className="form-control form-control-lg form-control-solid "
              name="phone"
              defaultValue={Phone}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label">
          Correo
        </label>
        <div className="col-lg-9 col-xl-6">
          <div className="input-group input-group-lg input-group-solid">
            
            <input
              type="email"
              placeholder=""
              className="form-control form-control-lg form-control-solid "
              name="email"
              defaultValue={Email}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="row mb-6">
        <label className="col-xl-3" />
        <div className="col-lg-9 col-xl-6">
          <h5 className="font-weight-bold mt-10 mb-6">Información de empresa</h5>
        </div>
      </div>
      <div className="form-group row mb-6" >
        <label className="col-xl-3 col-lg-3 col-form-label">Empresa</label>
        <div className="col-lg-9 col-xl-6">
          <input
            type="text"
            placeholder=""
            className="form-control form-control-lg form-control-solid"
            name="firstname"
            defaultValue={Company}
            readOnly
          />
        </div>
      </div>
      <div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label">Número de empleados</label>
        <div className="col-lg-9 col-xl-6">
          <input
            type="text"
            placeholder=""
            className="form-control form-control-lg form-control-solid "
            name="lastname"
            defaultValue={Employees}
            readOnly
          />
        </div>
      </div>
      <div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label">Estado</label>
        <div className="col-lg-9 col-xl-6">
          <input
            type="text"
            placeholder=""
            className="form-control form-control-lg form-control-solid "
            name="lastname"
            defaultValue={State}
            readOnly
          />
        </div>
      </div>
      <div className="form-group row mb-6">
        <label className="col-xl-3 col-lg-3 col-form-label">Ciudad</label>
        <div className="col-lg-9 col-xl-6">
          <input
            type="text"
            placeholder=""
            className="form-control form-control-lg form-control-solid "
            name="lastname"
            defaultValue={City}
            readOnly
          />
        </div>
      </div>
    </div>
  </div>
</form>

    </>
  )
}

export {MiPerfil}
