import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
// const API_URL = 'http://api-valeria.test/app'
// const API_URL = process.env.API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verifyToken`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/passwordResetToken`
export const UPDATE_PASSWORD_URL = `${API_URL}/updatePassword`
export const VALIDATE_RESET_TOKEN = `${API_URL}/validateResetToken`

// Server should return AuthModel
export function login(email: string, pass: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    pass,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  phone: string,
  company: string,
  employees: string,
  city: string,
  state: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    firstname: firstname,
    lastname: lastname,
    phone,
    company,
    employees,
    city,
    state,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function updatePass(email: string | null | undefined, pass: string) {
  return axios.post<{result: boolean}>(UPDATE_PASSWORD_URL, {
    email,
    pass,
  })
}

export function validateResetToken(key: string, token: string) {
  return axios.post<{result: boolean}>(VALIDATE_RESET_TOKEN, {
    key,
    token
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
