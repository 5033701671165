import React, {useEffect, useState} from 'react'

import axios from 'axios'

import {KTSVG} from '../../../_metronic/helpers'
import {useAuth} from '../../modules/auth'
import {Expediente} from '../../components/Expediente/Expediente'
import ReactPaginate from 'react-paginate'
import {Modal} from 'react-bootstrap'

import './BoletinJurisdiccional.css'
import Swal from 'sweetalert2'


import $ from 'jquery';
window.$ = $;




function BoletinJurisdiccional() {
  const API_URL = process.env.REACT_APP_API_URL
  const {currentUser, logout} = useAuth()

  const fecha = new Date()
  const [fechaLastUpdate, setFechaLastUpdate] = useState(new Date())

  const [loadingPage, setLoadingPage] = useState(false)

  const [expedientes, setExpedientes] = useState([])
  const [loadingNewExp, setLoadingNewExp] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const [statusValue, setStatusValue] = useState('')

  const [showBulkLoad, setShowBulkLoad] = useState(false)
  const handleCloseBulkLoad = () => setShowBulkLoad(false)
  const handleShowBulkLoad = () => setShowBulkLoad(true)
  
  let searchedExpedientes = new Array()



  if (expedientes.length !== 0) {
    searchedExpedientes = expedientes.filter((expediente) => {

      if(searchValue !=''){
        return expediente.expediente.includes(searchValue) || expediente.parteActora.includes(searchValue) || expediente.parteActora.toLowerCase().includes(searchValue) || expediente.parteActora.toUpperCase().includes(searchValue);
      }else if(statusValue == 'TODOS'){
        return expediente
      }else{
        return String(expediente.notificacionVencida).includes(statusValue)
      }
      
    })
  }

  

  /*if (expedientes.length !== 0) {
    searchedExpedientes = expedientes.filter((expediente) => {

      if(statusValue == 'TODOS'){
        return expediente
      }else{
        return String(expediente.notificacionVencida).includes(statusValue)
      }
    })
    
  }*/

  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(30)

  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
  const currentRecords = searchedExpedientes.slice(indexOfFirstRecord, indexOfLastRecord)

  const [showNewExp, setShowNewExp] = useState(false)
  const handleCloseNewExp = () => setShowNewExp(false)
  const handleShowNewExp = () => setShowNewExp(true)

  const paginate = ({selected}) => {
    setCurrentPage(selected + 1)
  }

  function addZero(i) {
    if (i < 10) {
      i = '0' + i
    }
    return i
  }

  function formatoFecha(fecha, formato) {
    const meses = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ]

    const map = {
      dd: fecha.getDate(),
      mm: meses[fecha.getMonth()],
      yyyy: fecha.getFullYear(),
      HH: addZero(fecha.getHours()),
      MM: addZero(fecha.getMinutes()),
    }

    return formato.replace(/mm|dd|HH|MM|yyyy/gi, (matched) => map[matched])
  }

  const [file, setFile] = useState()

  function handleChange(event) {
    setFile(event.target.files[0])
  }
  const formData = new FormData();
  formData.append('file', file);
  formData.append('idUser', currentUser.id);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const uploadBulkLoad = () => {
    axios.post(API_URL + '/bulkLoad', formData, config).then(
      (res) => {
        console.log(res)
        boletinJurisdiccional()
        setLoadingNewExp(false)
        handleCloseBulkLoad()
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se han registrado los expedientes",
          showConfirmButton: false,
          timer: 1500
        });
      },
      (error) => {
        alert(error)
        setLoadingNewExp(false)
      }
    )
  }


  const uploadNewExp = () => {
    setLoadingNewExp(true)
    let data = []
    const expName = (document.getElementById('new-exp-name').value).replace(/ /g, "")
    const parteActora = document.getElementById('parte-actora').value



    data = {
      idUser: currentUser.id,
      expName: expName,
      parteActora: parteActora,
    }

 
    axios.post(API_URL + '/newExp', data, {}).then(
      (res) => {
        console.log(res)
        boletinJurisdiccional()
        setLoadingNewExp(false)
        handleCloseNewExp()
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se ha registrado el nuevo expediente",
          showConfirmButton: false,
          timer: 1500
        });
      },
      (error) => {
        alert(error)
        setLoadingNewExp(false)
      }
    )
  }

  const getLastUpdate = () => {
    axios
      .get(API_URL + '/lastUpdate/')
      .then((response) => {
        console.log(response.data)

        // Split timestamp into [ Y, M, D, h, m, s ]
        var t = response.data[0].lastUpdateTimestamp.split(/[- :]/)
        console.log(t)

        // Apply each element to the Date function
        var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])

        setFechaLastUpdate(d)
      })
      .catch((error) => console.error(error))
  }

  const boletinJurisdiccional = () => {
    setLoadingPage(true)
    axios
      .get(API_URL + '/boletinJurisdiccional/' + currentUser.id)
      .then((response) => {
        console.log(response.data)
        setExpedientes(response.data)
        setLoadingPage(false)
      })
      .catch((error) => console.error(error))
  }













  useEffect(() => {
    getLastUpdate()
    boletinJurisdiccional()
    $('select').each(function () {

      // Cache the number of options
      var $this = $(this),
          numberOfOptions = $(this).children('option').length;
    
      // Hides the select element
      $this.addClass('s-hidden');
    
      // Wrap the select element in a div
      $this.wrap('<div class="select"></div>');
    
      // Insert a styled div to sit over the top of the hidden select element
      $this.after('<div class="styledSelect"></div>');
    
      // Cache the styled div
      var $styledSelect = $this.next('div.styledSelect');
    
      // Show the first select option in the styled div
      $styledSelect.text($this.children('option').eq(0).text());
    
      // Insert an unordered list after the styled div and also cache the list
      var $list = $('<ul />', {
          'class': 'options'
      }).insertAfter($styledSelect);
    
      // Insert a list item into the unordered list for each select option
      for (var i = 0; i < numberOfOptions; i++) {
          $('<li />', {
              text: $this.children('option').eq(i).text(),
              rel: $this.children('option').eq(i).val()
          }).appendTo($list);
      }
    
      // Cache the list items
      var $listItems = $list.children('li');
    
      // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
      $styledSelect.click(function (e) {
          e.stopPropagation();
          $('div.styledSelect.active').each(function () {
              $(this).removeClass('active').next('ul.options').hide();
          });
          $(this).toggleClass('active').next('ul.options').toggle();
      });
    
      // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
      // Updates the select element to have the value of the equivalent option
      $listItems.click(function (e) {
        
    
          
          e.stopPropagation();
          $styledSelect.text($(this).text()).removeClass('active');
          $this.val($(this).attr('rel'));
          $list.hide();
          setStatusValue($this.val());
          setCurrentPage(1);
          
          /* alert($this.val()); Uncomment this for demonstration! */
      });
    
      // Hides the unordered list when clicking outside of it
      $(document).click(function () {
          $styledSelect.removeClass('active');
          $list.hide();
      });
    
    });
  }, [])



  return (
    <>
      {/* Modal nuevo expediente */}
      <Modal show={showNewExp} onHide={handleCloseNewExp}>
        <Modal.Header closeButton>
          <Modal.Title>Dar de alta expediente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='form-group col-md-12'>
              <label className='alerta-modal'><strong>Agrega el número de expediente tal y como aparece en el boletín. Asegúrate no haya espacios ni caracteres diferentes, ya que la más mínima variación, afectará en las notificaciones del mismo.</strong></label>
              <label>Nuevo expediente:</label>
              <input type='text' className='form-control' id='new-exp-name' autoFocus/>
            </div>
          </div>
          <br></br>
          <div className='row'>
            <div className='form-group col-md-12'>
              <label>Parte actora (opcional):</label>
              <input type='text' className='form-control' id='parte-actora' />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={handleCloseNewExp}>
            Cerrar
          </button>
          <button type='button' className='btn btn-primary' onClick={() => uploadNewExp()}>
            {!loadingNewExp && <span className='indicator-label'>Guardar</span>}
            {loadingNewExp && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Por favor espere...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal carga masiva */}
      <Modal show={showBulkLoad} onHide={handleCloseBulkLoad}>
        <Modal.Header closeButton>
          <Modal.Title>Carga masiva de expedientes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='form-group col-md-12'>
              <label className='alerta-modal'><strong>1. Para iniciar, descarga el siguiente formato:</strong></label>
              <a href="https://api.adinte.legal/formato/Adinte-Carga-Masiva.xlsx" target='_blank' className='btn btn-primary btn-download'>Descargar formato</a>
            </div>
          </div>
          <br></br>
          <div className='row'>
            <div className='form-group col-md-12'>
              <label className='alerta-modal'><strong>2. Una vez descargado, llena los campos y adjúntalo:</strong></label><br></br>
              <label>Subir formato:</label>
              <input type='file' className='form-control' id='file-bulk-load' name='file-bulk-load'  autoFocus accept=".xlsx, .xls" required onChange={handleChange}/>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary' onClick={handleCloseBulkLoad}>
            Cerrar
          </button>
          <button type='button' className='btn btn-primary' onClick={() => uploadBulkLoad()}>
            {!loadingNewExp && <span className='indicator-label'>Cargar</span>}
            {loadingNewExp && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Por favor espere...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Header Inicio */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-lg-6'>
          <h2>Bienvenido</h2>
          <p>{formatoFecha(fecha, 'dd de mm yyyy - HH:MM hrs')}</p>
        </div>
        <div className='text-right col-lg-6'>
          <button
            type='button'
            className='btn btn-primary float-end w-60'
            onClick={handleShowNewExp}
          >
            Nuevo expediente
          </button>
          <button
            type='button'
            className='btn float-end w-60 mr-10 btn-bulk-load'
            onClick={handleShowBulkLoad}
          >
            Carga masiva de expedientes
          </button>
        </div>
      </div>

      {/* Card container */}
      <div className='row gy-5 g-xl-8 card card-custom shadow mt-4 pb-10'>
        <div className='row p-6 pb-15'>
          <div className='col-md-6'>
            <h4>Boletín jurisdiccional</h4>
            <li className='d-flex align-items-center py-2'>
              <span className='bullet bullet-dot bg-success h-15px w-15px me-5'></span>
              <b>Última conexión al boletín:&nbsp;</b>
              {formatoFecha(fechaLastUpdate, 'dd de mm yyyy - HH:MM hrs')}
            </li>
          </div>
          <div className='col-md-3'>
            <select  
              aria-label="Selecciona" 
              id="select-custom" 
              name="estatus">
              <option selected disabled>FILTRAR POR</option>
              <option value="true">SURTIÓ EFECTOS</option>
              <option value="false">AÚN NO SURTE EFECTOS</option>
              <option value="TODOS">TODOS</option>
            </select>

          </div>
          <div className='col-md-3 text-right'>
            <div className='form-group float-end pt-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 ms-0'
              />
              <input
                type='text'
                className='form-control form-control-flush ps-10 inicio-search-box mt-n10'
                name='search'
                placeholder='Buscar expediente...'
                value={searchValue}
                onChange={(event) => {
                  setSearchValue(event.target.value)
                  setCurrentPage(1)
                }}
              />
            </div>
          </div>
        </div>

        {/* Exp container */}
        <div className='row mb-10'>
          {!loadingPage && <Expediente expedientes={currentRecords} />}
          {loadingPage && (
            <>
              <div className='d-flex flex-center'>
                <h2>
                  <span className='spinner-border spinner-border align-middle ms-2'></span>
                  Cargando...
                </h2>
              </div>
            </>
          )}
        </div>
        <div className='row text-center'><p>Mostrando {currentRecords.length} de {searchedExpedientes.length} resultados</p></div>
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(searchedExpedientes.length / recordsPerPage)}
          breakLabel={'...'}
          previousLabel={'< Anterior'}
          nextLabel={'Siguiente >'}
          containerClassName={'pagination'}
          pageLinkClassName={'page-number'}
          previousLinkClassName={'page-number'}
          nextLinkClassName={'page-number'}
          activeLinkClassName={'active'}
          forcePage={currentPage - 1}
        />
      </div>
    </>
  )
}




export {BoletinJurisdiccional}
