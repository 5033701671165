import React, {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {TablaUsuarios} from '../../components/TablaUsuarios/TablaUsuarios'
import axios from 'axios'
import {KTSVG} from '../../../_metronic/helpers'
import ReactPaginate from 'react-paginate'
import {Modal} from 'react-bootstrap'



function Usuarios() {
  const API_URL = process.env.REACT_APP_API_URL
  const {currentUser, logout} = useAuth()

  const fecha = new Date()
  const [fechaLastUpdate, setFechaLastUpdate] = useState(new Date())

  const [loadingPage, setLoadingPage] = useState(false)

  const [usuarios, setUsuarios] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [loadingNewExp, setLoadingNewExp] = useState(false)

  let searchedUsuarios = new Array()

  if (usuarios.length !== 0) {
    searchedUsuarios = usuarios.filter((usuario) => {
      return usuario.first_name.includes(searchValue)
    })
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(25)

  const indexOfLastRecord = currentPage * recordsPerPage
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
  const currentRecords = searchedUsuarios.slice(indexOfFirstRecord, indexOfLastRecord)

 

  const paginate = ({selected}) => {
    setCurrentPage(selected + 1)
  }

  function addZero(i) {
    if (i < 10) {
      i = '0' + i
    }
    return i
  }

  function formatoFecha(fecha, formato) {
    const meses = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ]

    const map = {
      dd: fecha.getDate(),
      mm: meses[fecha.getMonth()],
      yyyy: fecha.getFullYear(),
      HH: addZero(fecha.getHours()),
      MM: addZero(fecha.getMinutes()),
    }

    return formato.replace(/mm|dd|HH|MM|yyyy/gi, (matched) => map[matched])
  }

 



  


  const misUsuarios = () => {
    setLoadingPage(true)
    axios
      .get(API_URL + '/usuarios/' + currentUser.id)
      .then((response) => {
        console.log(response.data)
        setUsuarios(response.data)
        setLoadingPage(false)
      })
      .catch((error) => console.error(error))
  }

  

  useEffect(() => {
    misUsuarios()
  }, [])

  return (
    <>
      

      {/* Header */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-lg-8'>
          <h2>Bienvenido</h2>
          <p>{formatoFecha(fecha, 'dd de mm yyyy - HH:MM hrs')}</p>
        </div>
   
      </div>

      <div className='row gy-5 g-xl-8 card card-custom shadow mt-4 pb-10'>
        <div className='row p-6'>
          <div className='col-md-4'>
            {/* <h4>Boletín jurisdiccional</h4>
            <li className='d-flex align-items-center py-2'>
              <span className='bullet bullet-dot bg-success h-15px w-15px me-5'></span>
              <b>Última conexión al boletín:&nbsp;</b>
              {formatoFecha(fechaLastUpdate, 'dd de mm yyyy - HH:MM hrs')}
            </li> */}
          </div>
          <div className='col-md-8 text-right'>
            <div className='form-group float-end pt-3'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 ms-0'
              />
              <input
                type='text'
                className='form-control form-control-flush ps-10 inicio-search-box mt-n10'
                name='search'
                placeholder='Buscar usuario...'
                value={searchValue}
                onChange={(event) => {
                  setSearchValue(event.target.value)
                  setCurrentPage(1)
                }}
              />
            </div>
          </div>
        </div>

        {/* Exp container */}
        <div className='row mb-10 gy-5'>
          {!loadingPage && (
            <>
              <div className='expedientes'>
                <table className='table table-rounded table-hover table-row-bordered border gy-7 gs-7'>
                  <thead>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200 expedientes-header'>
                      <th scope='col'>
                        <b>Nombre</b>
                      </th>
                      <th scope='col'>
                        <b>Teléfono</b>
                      </th>
                      <th scope='col'>
                        <b>Correo</b>
                      </th>
                      <th scope='col'>
                        <b>Epresa</b>
                      </th>
                      <th scope='col'>
                        <b>Número de empleados</b>
                      </th>
                      <th scope='col'>
                        <b>Estado</b>
                      </th>
                      <th scope='col'>
                        <b>Ciudad</b>
                      </th>
                      <th scope='col'>
                        <b>Expedientes</b>
                      </th>
                      <th scope='col'>
                        <b>Días restantes </b>
                      </th>
                     
                    </tr>
                  </thead>
                  <tbody>
                    <TablaUsuarios usuarios={currentRecords} />
                  </tbody>
                 
                </table>
              </div>
            </>
          )}
          {loadingPage && (
            <>
              <div className='d-flex flex-center'>
                <h2>
                  <span className='spinner-border spinner-border align-middle ms-2'></span>
                  Cargando...
                </h2>
              </div>
            </>
          )}
        </div>
        <div className='row text-center'><p>Mostrando {currentRecords.length} de {searchedUsuarios.length} resultados</p></div>
        <ReactPaginate
          onPageChange={paginate}
          pageCount={Math.ceil(searchedUsuarios.length / recordsPerPage)}
          breakLabel={'...'}
          previousLabel={'< Anterior'}
          nextLabel={'Siguiente >'}
          containerClassName={'pagination'}
          pageLinkClassName={'page-number'}
          previousLinkClassName={'page-number'}
          nextLinkClassName={'page-number'}
          activeLinkClassName={'active'}
          forcePage={currentPage - 1}
          
        />
      </div>
    </>
  )
}

export {Usuarios}
